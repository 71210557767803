import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  userProduct?: any;
}

const initialState: AuthState = {
  userProduct: undefined,
};

export const userProductSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setUserProduct: (state: any, { payload }: any) => {
      state.userProduct = payload;
    },
  },
});

export const { setUserProduct } = userProductSlice.actions;

export default userProductSlice.reducer;
