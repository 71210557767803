// PendingTagMultiSelect.tsx
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import useAuth from 'hooks/auth';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface PendingTagMultiSelectProps {
  partner: string;
  persona: string;
  fileName: string;
  sourcepage: string;
  id: string;
  selectedOptions: string[];
  onSelectionChange: (selectedOptions: string[]) => void;
  isEditing: boolean;
  options: { value: string; label: string }[];
  setOptions: React.Dispatch<
    React.SetStateAction<{ value: string; label: string }[]>
  >;
  newOptions?: any[] | null;
  onRemoveTag: (fileId: any, tagId: any, edited: any) => void;
  onSaveTag: (id: string, saveTags: any[]) => void;
  onCancel: () => void;
}

const PendingTagMultiSelect: React.FC<PendingTagMultiSelectProps> = ({
  partner,
  persona,
  fileName,
  sourcepage,
  id,
  selectedOptions,
  onSelectionChange,
  isEditing,
  options,
  setOptions,
  newOptions = null,
  onRemoveTag,
  onSaveTag,
  onCancel,
}) => {
  const toast = useToast();
  const [selectedItems, setSelectedItems] = useState<
    { value: string; label: string }[]
  >(() => selectedOptions.map((option) => ({ value: option, label: option })));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedItems(
      selectedOptions.map((option) => ({ value: option, label: option }))
    );
  }, [selectedOptions]);

  const handleChange = (newValue: any, actionMeta: any, parent: string) => {
    const updatedItems = selectedItems.filter(
      (item) =>
        !groupedOptions[parent]?.some(
          (option: any) => option.value === item.value
        )
    );

    const newItems = newValue.map((item: any) => ({
      value: item.value,
      label: item.label,
    }));

    const allSelectedItems = [...updatedItems, ...newItems];

    setSelectedItems(allSelectedItems);
    onSelectionChange(allSelectedItems.map((item: any) => item.value));
  };

  const addNewTagsToGroupedOptions = (
    newOptions: any,
    groupedOptions: any
  ): any => {
    if (!newOptions) return groupedOptions;

    newOptions.forEach((option: any) => {
      const tagTypeName = option.tag_type_name;
      const newTag = {
        value: option.tag,
        label: option.tag,
        data: option,
      };

      if (groupedOptions[tagTypeName]) {
        groupedOptions[tagTypeName].push(newTag);
      }
    });

    return groupedOptions;
  };

  const handleSaveTag = async () => {
    setIsLoading(true);
    const previousSelectedValues = selectedOptions.map((opt) => opt);
    const currentSelectedValues = selectedItems.map((item) => item.value);
    const removedTags = previousSelectedValues.filter(
      (prevValue) => !currentSelectedValues.includes(prevValue)
    );
    const addedTags = currentSelectedValues.filter(
      (currValue) => !previousSelectedValues.includes(currValue)
    );
    for (const removedValue of removedTags) {
      const removedOption = options.find((opt) => opt.value === removedValue);
      const objectToRemove = newOptions?.find(
        (newOpt) => newOpt.tag === removedValue
      );

      if (objectToRemove) {
        console.info('object to remove:', objectToRemove);
        onRemoveTag(objectToRemove.chunk_id, objectToRemove.id, false);
      } else if (removedOption) {
        console.info('Removed Tag:', removedOption);
      }
    }

    const addItems = addedTags
      .map((tag) => {
        const newlyAdded = newOptions?.find((newOpt) => newOpt.tag === tag);
        if (newlyAdded) {
          return {
            id: uuidv4(), // Use UUID for new tags or if ID is null
            persona_id: newlyAdded.persona_id || null,
            file_name: newlyAdded.file_name || fileName,
            chunk_id: newlyAdded.chunk_id || id,
            tag_type_id: newlyAdded.tag_type_id || null,
            tag: newlyAdded.tag,
            is_new_tag: false,
            tag_status: newlyAdded.tag_status || 0,
            tag_type_name: newlyAdded.tag_type_name || newlyAdded.label, // Use label if tag_type_name is null
            edited: true, // Track that this tag is edited
          };
        } else {
          // Create an object with the required fields, and set edited = true
          const fallbackLabel =
            options.find((opt) => opt.value === tag)?.label || tag;
          return {
            id: uuidv4(), // Use UUID for new tags
            persona_id: null,
            file_name: fileName,
            chunk_id: id,
            tag_type_id: null,
            tag: tag,
            is_new_tag: false,
            tag_status: 0,
            tag_type_name: fallbackLabel, // Use the label if available, or the tag as fallback
            edited: true, // Track that this tag is edited
          };
        }
      })
      .filter((item) => item !== null);

    // Print the entire addItems array
    // console.info('Full added items:', addItems);

    if (addItems.length > 0) {
      onSaveTag(id, addItems);
    }

    // if (addItems.length > 0) {
    //   console.info('addItems:', addItems);
    //   // await handleUpdateTag(addItems);
    // }

    setIsLoading(false);
    onCancel();
  };

  const groupOptionsByParent = (options: any) => {
    return options.reduce((acc: any, option: any) => {
      const parent = option.parent_tag_type_name || option.label;
      const key =
        parent === option.label ? parent : `${parent}: ${option.label}`;

      if (!acc[key]) acc[key] = [];

      acc[key].push({ value: option.value, label: option.value, data: option });

      return acc;
    }, {} as { [key: string]: { value: string; label: string; data: any }[] });
  };

  const groupedOptions = groupOptionsByParent(options);
  const updatedOptions = addNewTagsToGroupedOptions(newOptions, groupedOptions);
  // console.info('updatedOptions=======================');
  // console.info(updatedOptions);

  return (
    <>
      {isEditing ? (
        <Flex direction="column">
          <SimpleGrid columns={2} spacing={4} mt={4}>
            {Object.keys(updatedOptions).map((parent, index) => (
              <FormControl p={4} key={index}>
                <FormLabel>{parent}</FormLabel>
                <Select
                  isSearchable={false}
                  isMulti
                  isDisabled={!isEditing}
                  selectedOptionStyle="check"
                  name="tags"
                  options={updatedOptions[parent]}
                  value={selectedItems.filter((item) =>
                    updatedOptions[parent]?.some(
                      (option: any) => option.value === item.value
                    )
                  )}
                  onChange={(newValue, actionMeta) =>
                    handleChange(newValue, actionMeta, parent)
                  }
                  placeholder={`Select ${parent} tag...`}
                  closeMenuOnSelect={false}
                  chakraStyles={{
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      bg: 'transparent',
                      px: 2,
                      cursor: 'inherit',
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: 'none',
                    }),
                    groupHeading: (provided) => ({
                      ...provided,
                      fontWeight: 'bold',
                      color: 'gray',
                    }),
                    option: (provided) => ({
                      ...provided,
                      paddingLeft: '30px',
                    }),
                  }}
                />
              </FormControl>
            ))}
          </SimpleGrid>

          <Flex direction="column" mt={4} align="flex-start" p={4}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Flex direction="row" gap={4} align="center" width="100%">
                <Button
                  width="100px"
                  colorScheme="blue"
                  onClick={() => handleSaveTag()}
                >
                  Save tag
                </Button>
                <Button width="100px" colorScheme="gray" onClick={onCancel}>
                  Cancel
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
        <FormControl p={4}>
          <Flex flexWrap="wrap" gap={2}>
            {/* {selectedItems.map((option, idx) => (
              <Tag key={idx} variant="subtle" colorScheme="blue">
                <TagLeftIcon boxSize="12px" as={MdLabel} />
                <TagLabel>{option.label}</TagLabel>
              </Tag>
            ))} */}
          </Flex>
        </FormControl>
      )}
    </>
  );
};

export default PendingTagMultiSelect;
