import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  plans?: any;
}

const initialState: AuthState = {
  plans: undefined,
};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setPlans: (state: any, { payload }: any) => {
      state.plans = payload;
    },
  },
});

export const { setPlans } = planSlice.actions;

export default planSlice.reducer;
