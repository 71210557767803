export function extractAndRemoveUUID(input: string): {
  uuid: string | null;
  cleanedText: string;
  prompt: string | null;
} {
  const uuidRegex = /<UUID:([0-9a-fA-F\-]+)>/;
  const match = uuidRegex.exec(input);
  let uuid: string | null = null;
  if (match && match.length > 1) {
    uuid = match[1];
  }

  const promptRegex = /<PROMPTSTART>([\s\S]*?)<PROMPTEND>/;
  const promptMatch = promptRegex.exec(input);
  let prompt: string | null = null;
  if (promptMatch && promptMatch.length > 1) {
    prompt = promptMatch[1].trim();
  }

  let cleanedText = input.replace(promptRegex, '').trim();
  cleanedText = cleanedText.replace(uuidRegex, '').trim();

  return { uuid, cleanedText, prompt };
}

export function removeEndMarker(input: string): string {
  const endMarker = '<END>';
  if (input.endsWith(endMarker)) {
    return input.substring(0, input.length - endMarker.length);
  }
  return input;
}
