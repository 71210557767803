import {
  Box,
  Heading,
  Link,
  List,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Box p={8} maxW="7xl" mx="auto" mt={{ xl: '50px' }}>
      <VStack spacing={4} align="start">
        <Heading as="h1" size="xl">
          NuComply™ Application Privacy Policy
        </Heading>
        <Text fontSize="md">Effective Date: 06.09.2024</Text>

        <Heading as="h2" size="lg">
          Introduction
        </Heading>
        <Text>
          Welcome to NuComply™, NuArca's Generative AI Compliance application.
          We value your privacy and are committed to protecting your personal
          and business data. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you use our application
          ("App"), which currently utilizes the ChatGPT model hosted on
          Microsoft Azure OpenAI Service. The App is designed to be LLM-agnostic
          and allow for models that can be hosted in an organization's private
          cloud.
        </Text>
        <Text>
          By accessing or using our App, you agree to the terms of this Privacy
          Policy. If you do not agree with these terms, please do not use the
          App.
        </Text>

        <Heading as="h2" size="lg">
          Information We Collect
        </Heading>

        <Heading as="h3" size="md">
          Personal Information
        </Heading>
        <Text>
          We may collect personal information that you provide to us directly,
          including but not limited to:
        </Text>
        <List spacing={2} pl={5}>
          <ListItem>Name</ListItem>
          <ListItem>Email address</ListItem>
          <ListItem>Phone number</ListItem>
          <ListItem>Any other information you choose to provide</ListItem>
        </List>

        <Heading as="h3" size="md">
          Usage Data
        </Heading>
        <Text>
          We automatically collect certain data when you use the App, including:
        </Text>
        <List spacing={2} pl={5}>
          <ListItem>IP address</ListItem>
          <ListItem>Device type</ListItem>
          <ListItem>Operating system</ListItem>
          <ListItem>Browser type</ListItem>
          <ListItem>
            Usage details (e.g., access times, pages viewed, and interactions
            with the App)
          </ListItem>
        </List>

        <Heading as="h3" size="md">
          Communications
        </Heading>
        <Text>
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, and the
          content of your message.
        </Text>

        <Heading as="h2" size="lg">
          How We Use Your Information
        </Heading>
        <Text>
          We may use the information we collect for various purposes, including
          to:
        </Text>
        <List spacing={2} pl={5}>
          <ListItem>Provide, operate, and maintain our App</ListItem>
          <ListItem>Improve, personalize, and expand our App</ListItem>
          <ListItem>Understand and analyze how you use our App</ListItem>
          <ListItem>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the App, and for marketing
            and promotional purposes
          </ListItem>
          <ListItem>Process your transactions and manage your orders</ListItem>
          <ListItem>Find and prevent fraud</ListItem>
          <ListItem>Ensure compliance with legal obligations</ListItem>
        </List>

        <Heading as="h2" size="lg">
          Confidential Business Data
        </Heading>
        <Text>
          In the course of using our App, you may share confidential business
          data that is not personally identifiable information (PII). This data
          can include, but is not limited to:
        </Text>
        <List spacing={2} pl={5}>
          <ListItem>Organization-specific compliance questions</ListItem>
          <ListItem>Financial information</ListItem>
          <ListItem>Proprietary business processes</ListItem>
        </List>

        <Heading as="h3" size="md">
          How We Use Confidential Business Data
        </Heading>
        <Text>We use confidential business data to:</Text>
        <List spacing={2} pl={5}>
          <ListItem>
            Provide outputs that are unique and relevant to your organization
          </ListItem>
          <ListItem>Provide, operate, and maintain our App</ListItem>
          <ListItem>Improve, personalize, and expand our App</ListItem>
          <ListItem>Understand and analyze how you use our App</ListItem>
          <ListItem>Develop new products, services, and features</ListItem>
          <ListItem>Ensure compliance with legal obligations</ListItem>
        </List>

        <Heading as="h2" size="lg">
          Protection of Confidential Business Data
        </Heading>
        <Text>
          We are committed to maintaining the confidentiality of your business
          data. We implement a variety of security measures, including
          encryption, protected endpoints, secure authorizations, and access
          controls, to protect this data from unauthorized access, use, or
          disclosure.
        </Text>
        <Text>
          Specifically, while we use ChatGPT we are not sharing your inputs or
          outputs with other users of ChatGPT or NuComply. Each client's data is
          used only within its account; it is not used to train the ChatGPT
          models for other clients or competitors. Specifically, per the Azure
          OpenAI Service Privacy Policy:
        </Text>
        <List spacing={2} pl={5}>
          <ListItem>"Your prompts (inputs) and completions (outputs):</ListItem>
          <ListItem>are NOT available to other customers.</ListItem>
          <ListItem>are NOT available to OpenAI.</ListItem>
          <ListItem>are NOT used to improve OpenAI models.</ListItem>
          <ListItem>
            are NOT used to improve any Microsoft or 3rd party products or
            services.
          </ListItem>
          <ListItem>
            are NOT used for automatically improving Azure OpenAI models for
            your use in your resource (The models are stateless).
          </ListItem>
        </List>
        <Text>
          The Azure OpenAI Service is fully controlled by Microsoft; Microsoft
          hosts the OpenAI models in Microsoft's Azure environment and the
          Service does NOT interact with any services operated by OpenAI (e.g.
          ChatGPT, or the OpenAI API).
        </Text>

        <Heading as="h2" size="lg">
          Data Storage and Security
        </Heading>
        <Text>
          We use Microsoft Azure to host our ChatGPT endpoint and to store data.
          Microsoft Azure employs a range of security measures to protect your
          data, including encryption and access controls. We also implement
          reasonable security measures to protect your information from
          unauthorized access, use, or disclosure.
        </Text>

        <Heading as="h2" size="lg">
          Data Sharing and Disclosure
        </Heading>
        <Text>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties without your consent, except as described below:
        </Text>
        <List spacing={2} pl={5}>
          <ListItem>
            <Text as="span" fontWeight="bold">
              Service Providers:
            </Text>{' '}
            We may share your information with third-party service providers who
            perform services on our behalf and help us operate our App.
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="bold">
              Legal Requirements:
            </Text>{' '}
            We may disclose your information if required to do so by law or in
            response to valid requests by public authorities (e.g., a court or a
            government agency).
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="bold">
              Business Transfers:
            </Text>{' '}
            In the event of a merger, acquisition, or asset sale, your personal
            information may be transferred. We will provide notice before your
            personal information is transferred and becomes subject to a
            different privacy policy.
          </ListItem>
        </List>

        <Heading as="h2" size="lg">
          Other Terms of Use and Privacy Policy
        </Heading>
        <Text>
          Our App currently uses an Azure-hosted version of OpenAI's ChatGPT
          model, which is subject to OpenAI's Terms of Use and Microsoft Azure's
          Privacy Policy. By using our App, you also agree to comply with these
          terms and policies. Note, while direct users of OpenAI may provide
          more personal data than our App accepts or processes, NuArca's
          more-restrictive Privacy Policy provides a more protected environment
          and dataset. We encourage you to review OpenAI's policies to
          understand how your data may be handled by OpenAI.
        </Text>

        <Heading as="h2" size="lg">
          User Consent for API Calls
        </Heading>
        <Text>
          OpenAI handles user consent for API calls made through the ChatGPT
          model. By using our App, you acknowledge that your interactions with
          the ChatGPT model are processed by OpenAI, and you consent to the
          collection and use of data as outlined in OpenAI's policies. The types
          of data shared with the public API include:
        </Text>
        <List spacing={2} pl={5}>
          <ListItem>Text inputs provided by users</ListItem>
          <ListItem>Metadata such as timestamps of interactions</ListItem>
          <ListItem>
            Potentially sensitive information contained within the user inputs
          </ListItem>
        </List>

        <Heading as="h2" size="lg">
          Your Rights
        </Heading>
        <Text>
          Depending on your location, you may have the following rights
          regarding your personal information:
        </Text>
        <List spacing={2} pl={5}>
          <ListItem>
            <Text as="span" fontWeight="bold">
              The Right to Access:
            </Text>{' '}
            You have the right to request copies of your personal data.
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="bold">
              The Right to Rectification:
            </Text>{' '}
            You have the right to request that we correct any information you
            believe is inaccurate or complete information you believe is
            incomplete.
          </ListItem>
        </List>

        <Heading as="h2" size="lg">
          Contact Us
        </Heading>
        <Text>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </Text>
        <Text>
          Email:{' '}
          <Link href="mailto:compliance@nuarca.com">compliance@nuarca.com</Link>
        </Text>
        <Text>
          Address: NuArca Labs, Inc. 304 Cambridge St. Suite 210, Woburn, MA
          01801, USA
        </Text>
      </VStack>
    </Box>
  );
};

export default PrivacyPolicy;
