// sessionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newSessionLoad: '',
  // Add any other state properties here
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    newSessionFound: (state) => {
      if (state.newSessionLoad === 'called') {
        state.newSessionLoad = '_called';
      } else {
        state.newSessionLoad = 'called';
      }
    },
    // Add other reducers if needed
  },
});

export const { newSessionFound } = sessionSlice.actions;

export default sessionSlice;
