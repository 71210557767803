import factor1 from '../../assets/img/nucomply_factor1_trusted_answers_500px.png';
import factor2 from '../../assets/img/nucomply_factor2_automated_policy_assessments_500px.png';
import factor3 from '../../assets/img/nucomply_factor3_full_doc_testing_500px.png';
import factor4 from '../../assets/img/nucomply_factor4_institution-specific_500px.png';
import factor5 from '../../assets/img/nucomply_factor5_instant_regulatory_500px.png';
import factor6 from '../../assets/img/nucomply_factor6_marketing_500px.png';
import factor7 from '../../assets/img/nucomply_factor7_cc_marketing_500px.png';
import factor8 from '../../assets/img/nucomply_factor8_individualized_500px.png';


export default function Features() {
    return (
        <section id="features" className="nucomply_assistant  py-3">
        <div className="container my-0 py-2">

            <div className="row">
                <div className="col-md-10 m-auto order=1 align-self-start text-light p2 ">
                    <h1 className="leadin text-light p2 text-left" style={{ fontSize: '50px'}}><strong>Features </strong><br/></h1>
                </div>
            </div>
            <div className="row pt-5" data-aos="fade-right">
                <div className="row pt-5" data-aos="fade-right">
                    <div className="col-md-3 p-2 flexbox my-2">
                        <img src={factor1} className="focus_area_nucomply w-100" alt="Trusted Answers" title="Trusted Answers"/>
                    </div>

                    <div className="col-md-9 p-5 my-2 align-self-center text-md-left text-sm-center">
                        <h4 className="loud2"> Trusted Answers </h4>

                        <p className="text-light p2"> Get immediate accurate answers to regulatory and internal policy questions, saving time and reducing compliance risk with regulatory source citations
                        </p>
                    </div>
                </div>

                <div className="row py-5" data-aos="fade-left">
                    <div className="col-md-3 p-2 flexbox my-2">
                        <img src={factor2} className="focus_area_nucomply w-100" alt="Automated Policy Assessments" title="Automated Policy Assessments"/>
                    </div>

                    <div className="col-md-9 p-5 my-2 align-self-center text-md-left text-sm-center">
                        <h4 className="loud2"> Automated Policy Assessments, Edits, and Creation </h4>
                        <p className=" text-light p2">Effortlessly assess policies against both federal and state regulations to ensure compliance, auto-generate revisions, and whole new policies</p>
                    </div>
                </div>

                <div className="row " data-aos="fade-right">
                    <div className="col-md-3 p-2 flexbox my-2">
                        <img src={factor3} className="focus_area_nucomply w-100" alt="Full Document Testing" title="Full Document Testing" />
                    </div>

                    <div className="col-md-9 p-5 my-2 align-self-center text-md-left text-sm-center">
                        <h4 className="loud2"> 100% Document Testing </h4>
                        <p className=" text-light p2">Conduct comprehensive testing (not just sampling) on all loan documents and other products to ensure they meet all regulatory requirements </p>
                    </div>
                </div>

                <div className="row py-5" data-aos="fade-left">
                    <div className="col-md-3 p-2 flexbox my-2">
                        <img src={factor4} className="focus_area_nucomply w-100" alt="Institution-Specific Configuration" title="Institution-Specific Configuration"/>      
                    </div>
                    <div className="col-md-9 p-5 my-2 align-self-center text-md-left text-sm-center">
                        <h4 className="loud2"> Institution-Specific Configuration </h4>
                        <p className=" text-light p2">Adapt nucomply to your institution's specific regulatory environment, including charters, regulators, business lines, jurisdictions, and more</p>
                    </div>
                </div>

                <div className="row " data-aos="fade-right">
                    <div className="col-md-3 p-2 flexbox my-2">
                        <img src={factor5} className="focus_area_nucomply w-100" alt="Instant Regulatory Answers" title="Instant Regulatory Answers"/>
                    </div>

                    <div className="col-md-9 p-5 my-2 align-self-center text-md-left text-sm-center">
                        <h4 className="loud2"> Instant Regulatory Answers
                        </h4>
                        <p className=" text-light p2">Get immediate answers to regulatory and internal policy questions, saving you time and reducing compliance risk</p>
                    </div>

                </div>

                <div className="row py-5" data-aos="fade-left">
                    <div className="col-md-3 p-2 flexbox my-2">
                        <img src={factor6} className="focus_area_nucomply w-100" alt="Marketing Communication Review" title="Marketing Communication Review"/>
                    </div>

                    <div className="col-md-9 p-5 my-2 align-self-center text-md-left text-sm-center">
                        <h4 className="loud2"> Marketing Communication Review</h4>
                        <p className=" text-light p2">Ensure all consumer-facing marketing materials comply with regulatory standards</p>
                    </div>
                </div>

                <div className="row " data-aos="fade-right">
                    <div className="col-md-3 p-2 flexbox my-2">
                        <img src={factor7} className="focus_area_nucomply w-100" alt="CC Marketing Requirement Checks" title="CC Marketing Requirement Checks"/>
                    </div>

                    <div className="col-md-9 p-5 my-2 align-self-center text-md-left text-sm-center">
                        <h4 className="loud2"> CC Marketing Requirement Checks </h4>
                        <p className=" text-light p2">Identify deficiencies in credit card marketing requirements before they result in fines</p>
                    </div>
                </div>

                <div className="row py-5" data-aos="fade-left">
                    <div className="col-md-3 p-2 flexbox my-2">
                        <img src={factor8} className="focus_area_nucomply w-100" alt="Individualized Regulatory Change Assessments" title="Individualized Regulatory Change Assessments"/>
                    </div>

                    <div className="col-md-9 p-5 my-2 align-self-center text-md-left text-sm-center">
                        <h4 className="loud2"> Individualized Regulatory Change Assessments </h4>
                        <p className=" text-light p2">Receive personalized assessments of regulatory changes that affect your institution</p>


                    </div>

                </div>

            </div>



        </div>
    </section>
    )
}