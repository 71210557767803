import {
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormErrorMessage,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Select } from 'chakra-react-select';
import useAuth from 'hooks/auth';
import { useController, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import * as yup from 'yup';

const schema = yup.object().shape({
  signupReasons: yup
    .array()
    .required('You must select')
    .min(0, 'Please pick at least')
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
});

const MultiSelect = ({ category }: { category: any }) => {
  const toast = useToast();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      signupReasons: category.products
        .filter((product: any) => product.active)
        .map((product: any) => ({ label: product.name, value: product.id })),
    },
  });
  const { fetchUserProduct, ensureValidToken } = useAuth();

  const onSubmit = async (values: any) => {
    const token = await ensureValidToken(localStorage.getItem('token')); 

    console.info('====================================');
    console.info(values);
    console.info('====================================');
    const selectedIds = values.signupReasons.map((item: any) =>
      parseInt(item.value, 10)
    );
    console.info('Selected IDs:', selectedIds);

    const droppedActiveIds = selectedOptions
      .filter(
        (option: any) => !selectedIds.includes(parseInt(option.value, 10))
      )
      .map((option: any) => parseInt(option.value, 10));
    console.info('Dropped Active IDs:', droppedActiveIds);

    try {
      if (droppedActiveIds.length > 0) {
        const deleteProductResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/product/delete-user-products`,
          {
            user_id: profile?.userId,
            product_ids: droppedActiveIds,
          },
          { headers: { Authorization: `Bearer ${token}`  } }
        );
        if (deleteProductResponse.data.success) {
          toast({
            title: 'Products Deleted',
            description: 'Selected products have been deleted successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
            // variant: 'subtle',
          });
        } else {
          console.error(
            'Failed to delete products:',
            deleteProductResponse.data.message
          );
        }
      }

      if (selectedIds.length > 0) {
        const addProductResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/product/add-user-products`,
          {
            user_id: profile?.userId,
            product_ids: selectedIds,
          },
          { headers: { Authorization: `Bearer ${token}`  } }
        );
        if (addProductResponse.data.success) {
          toast({
            title: 'Products Added',
            description: 'Selected products have been added successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
            // variant: 'subtle',
          });
        } else {
          console.error(
            'Failed to add products:',
            addProductResponse.data.message
          );
        }
      }
      await fetchUserProduct();
      // console.info('PRODUCT  =====+++===', hasProduct);
      // dispatch(setUserProduct(hasProduct as any));
    } catch (error) {
      console.error('Error updating products:', error);
    }
  };

  const handleReset = () => {
    reset({
      signupReasons: category.products
        .filter((product: any) => product.active)
        .map((product: any) => ({ label: product.name, value: product.id })),
    });
  };

  const handleSelectAll = () => {
    const allOptions = category.products.map((product: any) => ({
      label: product.name,
      value: product.id,
    }));
    setValue('signupReasons', allOptions);
  };

  const {
    field: signupReasonsField,
    fieldState: { error: signupReasonsError },
  } = useController({
    name: 'signupReasons',
    control,
  });

  const allOptions = category.products.map((product: any) => ({
    label: product.name,
    value: product.id,
  }));
  const selectedOptions = category.products
    .filter((product: any) => product.active)
    .map((product: any) => ({ label: product.name, value: product.id }));
  console.info('category:', category);
  const isAssetSizeSelection = category.category_name === 'AssetSizeSelection';

  const handleChange = (value: any) => {
    let selectedValue;
    if (typeof value === 'object' && value.value === '') {
      selectedValue = [];
    } else {
      selectedValue = isAssetSizeSelection ? (value ? [value] : []) : value;
    }
    signupReasonsField.onChange(selectedValue);
  };

  return (
    <Container as="form">
      <Stack spacing={6}>
        <FormControl isInvalid={!!signupReasonsError}>
          {category && category.products && (
            <Select
              isMulti={!isAssetSizeSelection}
              options={
                isAssetSizeSelection
                  ? [{ label: 'Select One', value: '' }, ...allOptions]
                  : allOptions
              }
              closeMenuOnSelect={isAssetSizeSelection}
              defaultValue={selectedOptions}
              {...signupReasonsField}
              onChange={handleChange}
            />
          )}
          <FormErrorMessage>{signupReasonsError?.message}</FormErrorMessage>
        </FormControl>

        <ButtonGroup>
          <Button type="button" isLoading={isSubmitting} onClick={handleReset}>
            Reset
          </Button>
          {!isAssetSizeSelection && (
            <Button type="button" onClick={handleSelectAll}>
              Select All
            </Button>
          )}
          <Button
            type="button"
            isLoading={isSubmitting}
            colorScheme="blue"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </ButtonGroup>
      </Stack>
    </Container>
  );
};

export default MultiSelect;
function dispatch(arg0: { payload: any; type: 'product/setUserProduct' }) {
  throw new Error('Function not implemented.');
}
