import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

interface TileProps {
  title: string;
  onClick: () => void;
}

const Tile: React.FC<TileProps> = ({ title, onClick }) => {
  const textColor = useColorModeValue('navy.700', 'gray.200');

  return (
    <Box
      p={4}
      bg={useColorModeValue('white', 'navy.800')}
      borderRadius="md"
      boxShadow="md"
      onClick={onClick}
      cursor="pointer"
      _hover={{ bg: useColorModeValue('navy.600', 'navy.700') }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="80px" // Optional: Set a minimum height if needed
    >
      <Text color={textColor} fontWeight="normal">
        {title}
      </Text>
    </Box>
  );
};

export default Tile;
