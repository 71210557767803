// SEOComponent.tsx
import React from 'react';
import { Helmet } from 'react-helmet';

const APP_NAME = 'Nucomply';
const AUTHOR_NAME = 'Nuarca';
const BASE_URL = 'https://nucomply.com';
const DEFAULT_LANG = 'en';
const DEFAULT_LOCALE = 'en';
interface SEOProps {
  title: string;
  description?: string;
  canonical?: string;
  meta?: { name: string; content: string }[];
}

const SEOComponent: React.FC<SEOProps> = ({
  title,
  description = 'Default description for NuComply.',
  canonical,
  meta = [],
}) => {
  const isProd = window.location.hostname === 'nucomply.com';
  const isDev = !isProd;
  const url = window?.location.href || 'unknown';

  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: DEFAULT_LANG }}
      titleTemplate={`${APP_NAME} | %s`}
      link={
        isProd && canonical
          ? [{ rel: 'canonical', href: `${BASE_URL}${canonical}` }]
          : []
      }
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content:
            'compliance, regulations, policy, nucomply, AI compliance, compliance automation, regulatory technology, compliance management, risk management, policy management, regulatory compliance, compliance solutions, governance, legal compliance, automated compliance',
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },

        {
          property: 'og:site_name',
          content: APP_NAME,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:locale',
          content: DEFAULT_LOCALE,
        },
        {
          property: 'og:image',
          content: `${BASE_URL}logo192.png`,
        },
        {
          name: 'author',
          content: AUTHOR_NAME,
        },
        ...meta,
        ...(isDev
          ? [
              { name: 'robots', content: 'noindex' },
              { name: '“googlebot”', content: 'noindex' },
            ]
          : []),
      ]}
    />
  );
};

export default SEOComponent;
