import { Flex, useBreakpointValue, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useAbortController } from 'hooks/AbortControllerContext';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SEOComponent from 'seo';
import { setInputMessage } from 'store/reducers/inputMessage';
import {
  addMessage,
  clearMessages,
  updateLastMessage,
} from 'store/reducers/messages';
import { newSessionFound } from 'store/reducers/sessionSlice';
import { RootState } from 'store/store';
import { v4 as uuidv4 } from 'uuid';
import LogInModal from 'views/shared/component/LogInModal';
import AIModel from './AIModel';
import Divider from './Divider';
import ChatFooter from './Footer';
import Messages from './Messages';
import { extractAndRemoveUUID, removeEndMarker } from './utils';

export default function Chat() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const tab = queryParams.get('tab');
  const initialTab = parseInt(queryParams.get('tab') || '1', 10);
  const [tabValue, setTabValue] = useState(initialTab); // Manage tabValue in state
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const dispatch = useDispatch();
  const messages = useSelector((state: RootState) => state.messages);
  const inputMessage = useSelector((state: RootState) => state.inputMessage);
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const [newChat, setIsNewChat] = useState(false);
  const toast = useToast();
  const ws = useRef<WebSocket | null>(null);
  const { ensureValidToken } = useAuth();

  const flexRef = useRef<HTMLDivElement>(null);

  const [sessionId, setSessionId] = useState(null);

  const { abortController, setAbortController } = useAbortController();

  const handleSendWSMessage = () => {
    if (!ws.current) return;
    let intervalId: number | null = null;
    const bufferArray: string[] = [];

    ws.current.onmessage = (event) => {
      if (sessionStorage.getItem('session') === sessionId) {
        const message = event.data;
        let {
          uuid: answer_uuid,
          cleanedText,
          prompt,
        } = extractAndRemoveUUID(message);

        if (cleanedText.endsWith('<END>')) {
          if (
            process.env.REACT_APP_NODE_ENV === 'dev' ||
            process.env.REACT_APP_NODE_ENV === 'local'
          ) {
            console.info('PROMPT =================');
            console.info(prompt);
            console.info('=========================');
          }
          cleanedText = removeEndMarker(cleanedText);
          setLoading(false);
          if (newChat) {
            dispatch(newSessionFound());
            setIsNewChat(false);
          }
        }

        bufferArray.push(cleanedText);

        if (intervalId === null) {
          intervalId = window.setInterval(() => {
            if (bufferArray.length > 0) {
              const textToRender = bufferArray.shift();
              if (textToRender !== undefined) {
                dispatch(
                  addMessage({
                    from: 'computer',
                    text: textToRender,
                    uu_id: answer_uuid,
                  })
                );
              }
            } else {
              clearInterval(intervalId);
              intervalId = null;
            }
          }, 33); // Adjust the interval duration as needed
        }
      }
    };
  };

  const [sendButtonClicked, setSendButtonClicked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [attachedQuestion, setAttachedQuestion] = useState(null);
  const [progressArray, setProgressArray] = useState<number[]>(
    Array(selectedFiles.length).fill(0) // Initialize array with 0 progress for each file
  );
  const [isDragging, setIsDragging] = useState(false); // Track dragging

  const maxTotalSize = 1 * 1024 * 1024;

  // console.log(selectedFiles, sendButtonClicked);

// Function to handle file changes (including drop)
const handleFileChange = (newFiles: FileList | File[]) => {
  // Check if a file upload is already in progress
  console.log(newFiles);
  if (loading) {
    toast({
      title: 'File Upload in Progress',
      description: 'Please wait until the current file upload is completed.',
      status: 'warning',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
    return;
  }

  const filesArray = Array.from(newFiles);

  const currentTotalSize = selectedFiles.reduce(
    (acc, file) => acc + file.size,
    0
  );
  const newTotalSize = filesArray.reduce(
    (acc, file) => acc + file.size,
    currentTotalSize
  );

  // Check if the total file size exceeds the max limit
  if (newTotalSize > maxTotalSize) {
    toast({
      title: 'File Size Limit Exceeded',
      description: `Total file size exceeds 1 MB. Please select smaller files.`,
      status: 'warning',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
    return;
  }

  // Allowed file types: .pdf, .doc, .docx, .txt
  const allowedTypes = [
    'application/pdf',
    // 'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    // 'text/plain', // .txt
  ];

  // Filter valid files based on the allowed types
  const validFiles = filesArray.filter((file) =>
    allowedTypes.includes(file.type)
  );

  console.info('validFiles:', validFiles, filesArray);

  // Check if there are invalid files
  if (validFiles.length !== filesArray.length) {
    toast({
      title: 'Invalid File Type',
      description: 'Only PDF, and DOCX are allowed.',
      status: 'warning',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
    return;
  }

  // Check for duplicate files
  const nonDuplicateFiles = validFiles.filter(
    (file) =>
      !selectedFiles.some(
        (selectedFile) =>
          selectedFile.name === file.name && selectedFile.size === file.size
      )
  );

  if (nonDuplicateFiles.length !== validFiles.length) {
    toast({
      title: 'Duplicate Files Detected',
      description: 'Some files are already selected and will not be added again.',
      status: 'warning',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
  }

  if (nonDuplicateFiles.length > 5) {
    toast({
      title: 'File Upload Limit Exceeded',
      description:
        'You can only upload a maximum of 5 files at a time. Please remove some files and try again.',
      status: 'warning',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
    return;
  }

  // Add the non-duplicate valid files to the selected files
  setSelectedFiles((prevFiles) => [...prevFiles, ...nonDuplicateFiles]);
};

  // Function to handle drag-over event
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  // Function to handle drag-leave event
  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
  };

  // Function to handle the drop event
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setIsDragging(false);
    handleFileChange(files);
  };

  const handleRemoveFile = (fileName: string) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const simulateSlowerProgress = (
    actualProgress: number,
    setProgress: (value: number) => void
  ) => {
    let currentProgress = 0;
    const interval = setInterval(() => {
      if (currentProgress < Math.min(actualProgress * 90, 90)) {
        currentProgress += 1;
        setProgress(currentProgress);
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust this value to make the animation faster or slower
    return () => clearInterval(interval);
  };

  const handleSendFiles = async () => {
    setLoading(true);
    console.info(selectedFiles.length);

    if (selectedFiles.length > 0) {
      for (let index = 0; index < selectedFiles.length; index++) {
        const element = selectedFiles[index];
        let stopSimulation: (() => void) | null = null;

        try {
          setProgressForFile(index, 0); // Start progress at 0%

          const fileUpload = await handleUpload(element, (actualProgress) => {
            if (stopSimulation) stopSimulation();
            stopSimulation = simulateSlowerProgress(
              actualProgress,
              (progress) => {
                setProgressForFile(index, progress);
              }
            );
          });

          if (fileUpload) {
            if (stopSimulation) stopSimulation();
            setProgressForFile(index, 100); // Set to 100% only on successful upload
          } else {
            if (stopSimulation) stopSimulation();
            setProgressForFile(index, 0); // Reset progress on failure
            console.error(`File ${element.name} failed to upload.`);
            break; // Stop processing further files on failure
          }
        } catch (error) {
          if (stopSimulation) stopSimulation();
          setProgressForFile(index, 0); // Reset progress on error
          console.error(`Error uploading file ${element.name}:`, error);
          break; // Stop processing further files on error
        }

        // Wait for a short delay before starting the next file upload
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }

    setLoading(false);
  };

  // Modified handleUpload function to handle progress callback
  const handleUpload = async (
    file: File,
    onProgress: (progress: number) => void
  ) => {
    if (file) {
      const token = await ensureValidToken(localStorage.getItem('token'));

      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(
          `${process.env.REACT_APP_LLM_API_URL}/upload-user-file-to-azure?&user_id=${profile?.userId}&session_id=${sessionId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
            signal: abortController?.signal, // Only use the AbortController signal
            onUploadProgress: (progressEvent) => {
              const percentCompleted = progressEvent.total
                ? (progressEvent.loaded / progressEvent.total) * 100
                : 0;
              onProgress(percentCompleted);
            },
          }
        );

        if (response.data.success === true) {
          console.info('FILE upload done');
          return true;
        } else {
          toast({
            title: 'Upload Failed',
            description: response.data?.data || 'Something went wrong.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
          return false;
        }
      } catch (error: any) {
        if (error.message === 'canceled') {
          console.info('Request canceled:', error.message);
          toast({
            title: 'Upload Canceled',
            description:
              'The upload was canceled due to a tab change or user action.',
            status: 'warning',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
        } else {
          console.error('Error uploading file:', error);
          toast({
            title: 'Upload Failed',
            description: 'Failed to upload the file. Please try again later.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
        }
        return false;
      }
    } else {
      console.info('No file selected or no partner/persona selected.');
      return false;
    }
  };

  const setProgressForFile = (index: number, value: number) => {
    setProgressArray((prevProgress) => {
      const updatedProgress = [...prevProgress];
      updatedProgress[index] = value;
      return updatedProgress;
    });
  };

  const handleSendMessage = async (messageToSend?: string) => {
    // const token = await ensureValidToken(localStorage.getItem('token'));

    const message = messageToSend || inputMessage;
    let finalQuestion = '';
    const endTag = '</FILETAGEND>';

    if (message.trim().length > 0 && selectedFiles.length > 0) {
      console.info('FIRST condiion');
      dispatch(setInputMessage(''));
      setAttachedQuestion(message);
      await handleSendFiles();
      setAttachedQuestion(null);
      setSendButtonClicked(false);
      if (flexRef?.current?.innerHTML) {
        dispatch(
          addMessage({
            from: 'me',
            text: `${message}<FILETAGSTART>${flexRef?.current?.innerHTML}</FILETAGEND>`,
          })
        );
      }
      setSelectedFiles([]);
      setProgressArray([]);
      finalQuestion = `${message}<FILETAGSTART>${flexRef?.current?.innerHTML}</FILETAGEND>`;
      setLoading(false);
    } else if (selectedFiles.length > 0) {
      console.info('2nd condiion');
      await handleSendFiles();
      setSendButtonClicked(false);
      if (flexRef?.current?.innerHTML) {
        // dispatch(setInputMessage(''));
        dispatch(
          addMessage({
            from: 'me',
            text: `<FILETAGSTART>${flexRef?.current?.innerHTML}</FILETAGEND>`,
          })
        );
      }
      setSelectedFiles([]);
      setProgressArray([]);
      setLoading(false);
      return;
    } else {
      console.info('3rd condiion');
      const lastMessage = messages[messages.length - 1];

      let lastPDF = '';

      if (lastMessage?.text?.trim().endsWith(endTag)) {
        lastPDF = lastMessage?.text;
        finalQuestion = message + lastPDF;
        if (!message?.trim().length) {
          return;
        }
        dispatch(setInputMessage(''));
        dispatch(updateLastMessage({ from: 'me', text: finalQuestion }));

        console.info('Found the tag and no extra content after it.');
      } else {
        if (!message?.trim().length) {
          return;
        }
        finalQuestion = message + lastPDF;
        dispatch(setInputMessage(''));
        dispatch(addMessage({ from: 'me', text: lastPDF + message }));
      }
    }

    if (!ws) {
      connectWebSocket();
    }
    if (
      !ws.current ||
      ws.current.readyState === WebSocket.CLOSING ||
      ws.current.readyState === WebSocket.CLOSED
    ) {
      return;
    }

    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }

    const tabFromSession = parseInt(sessionStorage.getItem('activeTab') || '1');

    let partnerId, partner, persona;
    if (tabFromSession === 2) {
      partnerId = process.env.REACT_APP_STATE_PARTNER_ID;
      partner = process.env.REACT_APP_STATE_PARTNER;
      persona = process.env.REACT_APP_STATE_PERSONA;
    } else if (tabFromSession === 1) {
      partnerId = process.env.REACT_APP_PARTNER_ID;
      partner = process.env.REACT_APP_PARTNER;
      persona = process.env.REACT_APP_PERSONA;
    } else if (tabFromSession === 3) {
      partnerId = process.env.REACT_APP_CANADA_PARTNER_ID;
      partner = process.env.REACT_APP_CANADA_PARTNER;
      persona = process.env.REACT_APP_CANADA_PERSONA;
    }

    if (
      ws.current.readyState === WebSocket.CLOSING ||
      ws.current.readyState === WebSocket.CLOSED
    ) {
      console.warn(
        'WebSocket is closing or closed. Attempting to reconnect...'
      );
      toast({
        title: 'WebSocket Disconnected',
        description: 'Reconnecting...',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      connectWebSocket();
      return;
    }

    const uu_id = uuidv4().toString();
    dispatch(addMessage({ from: 'computer', text: ' ', uu_id: uu_id }));
    const token = await ensureValidToken(null);
    const requestBody = JSON.stringify({
      is_from_user_document: finalQuestion.endsWith(endTag),
      partner_id: partnerId,
      partner: partner,
      persona: persona,
      UUId: uu_id,
      question: finalQuestion,
      user_id: profile?.userId,
      session_id: sessionId,
      checklist_array: null,
      access_token: token,
    });
    if (
      process.env.REACT_APP_NODE_ENV === 'dev' ||
      process.env.REACT_APP_NODE_ENV === 'local'
    ) {
      console.info('requestBody =================');
      console.info(requestBody);
      console.info('=========================');
    }
    ws.current.send(requestBody);
    handleSendWSMessage();
  };

  const chatHeight = useBreakpointValue({
    base: '80vh',
    md: '85vh',
    xl: '78vh',
    '2xl': '82vh',
  });

  const connectWebSocket = () => {
    const newWs = new WebSocket(
      `${process.env.REACT_APP_LLM_WS_URL}/ws-get-answer`
    );

    newWs.onopen = () => {
      console.info('WebSocket connected');
      ws.current = newWs;
    };

    newWs.onclose = () => {
      console.info('WebSocket disconnected');
    };

    newWs.onerror = (error) => {
      console.error('WebSocket error:', error);
      toast({
        title: 'Error connecting websocket',
        description: 'Failed to connect websocket. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !ws.current) {
        // You may want to reconnect WebSocket or perform other actions here
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const getSessionData = async (session_id: string) => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    const getSessionURL = `${process.env.REACT_APP_LLM_API_URL}/get-session-data`;
    const requestBody = {
      session_id: session_id,
    };
    const response = await axios.post(getSessionURL, requestBody, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  };

  const fetchData = async (sessionId: any, dispatch: any) => {
    if (sessionId) {
      try {
        const old_chat = await getSessionData(sessionId);
        dispatch(clearMessages());
        old_chat.content.forEach((message: any) => {
          const { role, content } = message;
          if (role === 'user') {
            dispatch(addMessage({ from: 'me', text: content }));
          } else if (role === 'assistant') {
            dispatch(addMessage({ from: 'computer', text: content }));
          }
        });
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    }
  };

  const clearIntervalFunc = () => {
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);

    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  };
  const startNewSession = () => {
    const newSessionId = uuidv4();
    setIsNewChat(true);
    sessionStorage.setItem('session', newSessionId);
    setSessionId(newSessionId);
  };

  const closeCurrentWSConnection = () => {
    if (ws.current) {
      ws.current.close();
      ws.current.onmessage = null;
      ws.current = null;
    }
  };
  const handleTabChange = (newTab: number) => {
    // Create a new AbortController for canceling requests
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    // Save the active tab in sessionStorage
    sessionStorage.setItem('activeTab', newTab.toString());

    // Clean up the current session and reset state
    closeCurrentWSConnection();
    clearIntervalFunc();
    startNewSession();
    setSelectedFiles([]); // Reset selected files
    setProgressArray([]); // Reset progress array
    setAttachedQuestion(null);
    setLoading(false); // Stop any loading indicators
    setSendButtonClicked(false);

    // Clear input message and messages in the store
    dispatch(setInputMessage(''));
    dispatch(clearMessages());

    // Set the new active tab value
    setTabValue(newTab);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionIdFromURL = queryParams.get('sessionId');
    const tabValueFromURL = parseInt(queryParams.get('tab') || '1', 10);

    // Reset states when the component mounts or location changes
    dispatch(clearMessages());
    setSelectedFiles([]);
    setAttachedQuestion(null);
    setProgressArray([]);
    setLoading(false);
    setSendButtonClicked(false);

    if (sessionIdFromURL) {
      // If sessionId exists in the URL, initialize the session
      dispatch(setInputMessage(''));
      setSessionId(sessionIdFromURL);
      setTabValue(tabValueFromURL);
      sessionStorage.setItem('session', sessionIdFromURL);
      fetchData(sessionIdFromURL, dispatch);
    } else {
      // Start a new session if sessionId is not present
      startNewSession();
    }

    // Close current WebSocket connection and start a new one
    closeCurrentWSConnection();
    clearIntervalFunc();
    connectWebSocket();

    // Clean up by aborting any pending requests when the component unmounts
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [location, abortController]);

  return (
    <div
      style={{
        marginTop: '40px',
        position: 'relative',
        backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <SEOComponent
        title="Nucomply - Compliance Expert Assistant"
        description="Experience the future of compliance management with nucomply, and empower your institution with the tools needed to confidently navigate the complex regulatory landscape."
        canonical="/compliance/chat"
      />

      <AIModel tab={tabValue} onTabChange={handleTabChange} />
      <Flex w="100%" h={chatHeight} justify="center" align="center" mt="12px">
        <Flex
          w={['95%', '90%', '85%', '80%', '70%']}
          mx="auto"
          maxW="58rem"
          h="100%"
          flexDir="column"
        >
          <Messages
            handleSendMessage={handleSendMessage}
            tabValue={tabValue}
            selectedFiles={selectedFiles}
            attachedQuestion={attachedQuestion}
            sendButtonClicked={sendButtonClicked}
            progressArray={progressArray}
            flexRef={flexRef}
          />
          <Divider />
          <ChatFooter
            setSendButtonClicked={setSendButtonClicked}
            sendButtonClicked={sendButtonClicked}
            handleSendMessage={handleSendMessage}
            handleRemoveFile={handleRemoveFile}
            handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleFileChange(e.target.files)
            }
            selectedFiles={selectedFiles}
            loading={loading}
          />
        </Flex>
      </Flex>

      {!profile?.userId && <LogInModal />}
    </div>
  );
}
