import React, { createContext, useState, ReactNode, useContext } from 'react';

interface AbortControllerContextProps {
  abortController: AbortController | null;
  setAbortController: React.Dispatch<React.SetStateAction<AbortController | null>>;
}

const AbortControllerContext = createContext<AbortControllerContextProps | undefined>(undefined);

export const AbortControllerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  return (
    <AbortControllerContext.Provider value={{ abortController, setAbortController }}>
      {children}
    </AbortControllerContext.Provider>
  );
};

export const useAbortController = () => {
  const context = useContext(AbortControllerContext);
  if (!context) {
    throw new Error('useAbortController must be used within an AbortControllerProvider');
  }
  return context;
};
