import About from "./about";
import { Banner } from "./banner";
import { Nav } from "./nav";
import '../../styles/style_nucomply_landing.css';
import Features from "./features";
import { Footer } from "./footer";

export default function LandingPage() {
  return (
    <>
        <Nav />
        <Banner />
        <Features />
        <About />
        <Footer />
    </>
  );

}