/* eslint-disable */
// Chakra Imports
import {
  Avatar,
  Box,
  Flex,
  FormControl,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import useAuth from 'hooks/auth';
import { useEffect, useState } from 'react';
import { MdAutoAwesome, MdBolt, MdSunny } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { components } from 'react-select';
import routes from 'routes';
import { setAuthentication } from 'store/reducers/auth';
import { clearMessages } from 'store/reducers/messages';
import { RootState } from 'store/store';

export default function AdminNavbar(props: {
  secondary: boolean;
  message: string | boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
}) {
  const [scrolled, setScrolled] = useState(false);
  const isChatPage = location.pathname.includes('/chat');

  const getInitialTabValue = () => {
    const activeTabString = sessionStorage.getItem('activeTab');
    if (activeTabString === null || isNaN(parseInt(activeTabString, 10))) {
      return 1;
    }
    return parseInt(activeTabString, 10);
  };

  const [activeTab, setActiveTab] = useState<number>(getInitialTabValue);

  const profile = useSelector((state: RootState) => state.auth.authentication);
  const product = useSelector((state: RootState) => state.product.userProduct);
  const { login, logOut } = useAuth();

  useEffect(
    () => {
      window.addEventListener('scroll', changeNavbar);

      // Get the active tab from sessionStorage
      const activeTabString = sessionStorage.getItem('activeTab');
      if (activeTabString !== null) {
        const newActiveTab = parseInt(activeTabString, 10);
        if (!isNaN(newActiveTab)) {
          setActiveTab(newActiveTab);
        }
      }

      return () => {
        window.removeEventListener('scroll', changeNavbar);
      };
    },
    [
      /* Add dependencies here if needed */
    ]
  );

  const { secondary, brandText } = props;
  const history = useHistory();

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue('navy.700', 'white');
  let secondaryText = useColorModeValue('gray.700', 'white');
  let navbarPosition = 'fixed' as const;
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let menuBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  let navbarShadow = 'none';
  let navbarBg = useColorModeValue(
    'rgba(244, 247, 254, 0.2)',
    'rgba(11,20,55,0.5)'
  );
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '15px';
  let gap = '0px';
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const dispatch = useDispatch();
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleLogIn = () => {
    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }
  };

  const handleProductSetting = () => {
    history.push('/compliance/profile-overview');
  };
  const { colorMode } = useColorMode();
  const logoSrc =
    colorMode === 'dark' ? '/img/logo_light.png' : '/img/logo_dark.png';

  const handleTabChange = (newTab: number) => {
    sessionStorage.setItem('activeTab', newTab.toString());
    setActiveTab(newTab);
  };
  // console.log('activeTab Tab Value:', activeTab);

  const isDropdown = useBreakpointValue({ base: true, lg: false });

  const options = [
    { label: 'Federal', value: 1, icon: MdAutoAwesome },
    { label: 'Federal & State', value: 2, icon: MdBolt },
    { label: 'Canada', value: 3, icon: MdSunny },
  ];

  const handleSelectChange = (selectedOption: any) => {
    handleTabChange(selectedOption.value);
  };

  const CustomOption = (props: any) => (
    <components.Option {...props}>
      <Flex alignItems="center">
        <Icon as={props.data.icon} mr="8px" color="currentColor" />
        {props.data.label}
      </Flex>
    </components.Option>
  );

  const handleLogout = () => {
    localStorage.removeItem('authentication');
    sessionStorage.removeItem('product');
    sessionStorage.removeItem('plan');
    sessionStorage.removeItem('chat');
    dispatch(setAuthentication(undefined));
    dispatch(clearMessages());
    logOut();
  };

  const SingleValue = (props: any) => {
    const singleValueTextColor = useColorModeValue('navy.700', 'white');
    return (
      <components.SingleValue {...props}>
        <Flex alignItems="center" color={singleValueTextColor}>
          <Icon as={props.data.icon} mr="8px" color="currentColor" />
          {props.data.label}
        </Flex>
      </components.SingleValue>
    );
  };
  const sliceIndex = !product?.hasProduct && profile?.userId ? 4 : 3;

  const resResponsiveBrandText = useBreakpointValue({
    base: (
      <Flex alignItems="center" justifyContent="space-between">
        <Flex>
          <SidebarResponsive routes={routes.slice(0, sliceIndex)} />
          <Image src={logoSrc} alt="logo" w="130px" h="40px" />
        </Flex>

        {isChatPage && (
          <>
            <FormControl
              ml="auto"
              mr="auto"
              display="flex"
              justifyContent="center"
            >
              <Select
                value={options.find((option) => option.value === activeTab)}
                options={options}
                onChange={handleSelectChange}
                components={{ Option: CustomOption, SingleValue }}
                size="sm"
                chakraStyles={{
                  container: (provided) => ({
                    ...provided,
                    minWidth: '120px',
                    maxWidth: '300px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    fontSize: '0.8rem',
                  }),
                }}
              />
            </FormControl>
          </>
        )}

        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color="white"
              // name='User'
              bg="#0680ff"
              size="sm"
              w="40px"
              h="40px"
              src={profile?.user?.picture} // Set the src to the user's picture URL
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex flexDirection="column" p="10px">
              {!profile?.idToken && (
                <>
                  <Text
                    ps="10px"
                    pt="16px"
                    pb="10px"
                    w="100%"
                    borderBottom="1px solid"
                    borderColor={borderColor}
                    fontSize="sm"
                    fontWeight="700"
                    color={textColor}
                    onClick={handleLogIn}
                  >
                    👋&nbsp; Hey, Please login
                  </Text>
                  <MenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    color="blue.400"
                    borderRadius="8px"
                    px="14px"
                    onClick={handleLogIn}
                  >
                    <Text fontSize="sm">Log In</Text>
                  </MenuItem>
                </>
              )}
              {profile?.idToken && (
                <div>
                  {/* <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'
							onClick={handleProfileSetting}>
							<Text fontSize='sm'>Profile Settings</Text>
						</MenuItem> */}
                  <Text
                    ps="10px"
                    pt="16px"
                    pb="10px"
                    w="100%"
                    borderBottom="1px solid"
                    borderColor={borderColor}
                    fontSize="sm"
                    fontWeight="700"
                    color={textColor}
                  >
                    &nbsp; {profile?.userEmail}
                  </Text>
                  <MenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    onClick={handleProductSetting}
                    borderRadius="8px"
                    px="14px"
                  >
                    <Text fontSize="sm">Institution Profile</Text>
                  </MenuItem>
                  <MenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    color="red.400"
                    borderRadius="8px"
                    px="14px"
                    onClick={handleLogout}
                  >
                    <Text fontSize="sm">Log out</Text>
                  </MenuItem>
                </div>
              )}
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    ),
    xl: brandText,
  });

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration="0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      minH="75px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
      px={{
        // sm: paddingX,
        md: '10px',
      }}
      ps={{
        xl: '12px',
      }}
      pt="8px"
      top={{ base: '12px', md: '16px', xl: '18px' }}
      w={{
        base: 'calc(100vw - 6%)',
        md: 'calc(100vw - 8%)',
        lg: 'calc(100vw - 6%)',
        xl: 'calc(100vw - 350px)',
        '2xl': 'calc(100vw - 365px)',
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: 'column',
          xl: 'row',
        }}
        alignItems={{ xl: 'center' }}
        mb={gap}
      >
        <Box mb={{ sm: '8px', md: '0px' }}>
          {/* Here we create navbar brand, based on route name */}
          <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize="34px"
            _hover={{ color: { mainText } }}
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            {resResponsiveBrandText}
          </Link>
        </Box>
        <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Box>
  );
}
