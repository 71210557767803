import { createSlice } from "@reduxjs/toolkit";

export interface AuthState {    
    approvedFiles: any[][];
    pendingApprovalFiles: any[][];
}

const initialState: AuthState = {
    approvedFiles: [[]],
    pendingApprovalFiles: [[]],
};

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setApprovedFiles: (state: AuthState, { payload }: { payload: any[][] }) => {
            state.approvedFiles = payload;
        },
        setPendingApprovalFiles: (state: AuthState, { payload }: { payload: any[][] }) => {
            state.pendingApprovalFiles = payload;
        },
        updateApprovedFilesAtIndex: (state: AuthState, { payload }: { payload: { index: number, files: any[] } }) => {
            state.approvedFiles[payload.index] = payload.files;
        },
        updatePendingApprovalFilesAtIndex: (state: AuthState, { payload }: { payload: { index: number, files: any[] } }) => {
            state.pendingApprovalFiles[payload.index] = payload.files;
        },
        reset: () => initialState,
    },
});

export const { setApprovedFiles, setPendingApprovalFiles, updateApprovedFilesAtIndex, updatePendingApprovalFilesAtIndex, reset } = filesSlice.actions;

export const selectApprovedFiles = (state: { files: AuthState }) => state.files.approvedFiles;
export const selectPendingApprovalFiles = (state: { files: AuthState }) => state.files.pendingApprovalFiles;

export default filesSlice.reducer;