import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { Select } from 'chakra-react-select';
import useAuth from 'hooks/auth';
import React, { useEffect, useState } from 'react';
import { MdLabel } from 'react-icons/md';

interface TagMultiSelectProps {
  partner: string;
  persona: string;
  fileName: string;
  sourcepage: string;
  id: string;
  selectedOptions: string[];
  onSelectionChange: (selectedOptions: string[]) => void;
  isEditing: boolean;
  options: { value: string; label: string }[];
  setOptions: React.Dispatch<
    React.SetStateAction<{ value: string; label: string }[]>
  >;
  onSaveComplete: () => void;
}

const TagMultiSelect: React.FC<TagMultiSelectProps> = ({
  partner,
  persona,
  fileName,
  sourcepage,
  id,
  selectedOptions,
  onSelectionChange,
  isEditing,
  options,
  setOptions,
  onSaveComplete,
}) => {
  const toast = useToast();
  const [selectedItems, setSelectedItems] = useState<
    { value: string; label: string }[]
  >(() => selectedOptions.map((option) => ({ value: option, label: option })));
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false)
  const { ensureValidToken } = useAuth();

  useEffect(() => {
    setSelectedItems(
      selectedOptions.map((option) => ({ value: option, label: option }))
    );
    setSelectedTags(selectedOptions);
  }, [selectedOptions]);

  const handleChange = (newValue: any, actionMeta: any, parent: string) => {
    const updatedItems = selectedItems.filter(
      (item) =>
        !groupedOptions[parent]?.some(
          (option: any) => option.value === item.value
        )
    );
    const newItems = newValue.map((item: any) => ({
      value: item.value,
      label: item.label,
    }));
    const allSelectedItems = [...updatedItems, ...newItems];

    setSelectedItems(allSelectedItems);
    onSelectionChange(allSelectedItems.map((item: any) => item.value));
  };

  const handleSaveTag = async () => {
    const token = await ensureValidToken(localStorage.getItem('token')); 

    setIsLoading(true); // Set loading state to true before API call

    const tags: any = selectedItems.map((item) => item.value);
    const requestBody = {
      partner,
      persona,
      filename: fileName,
      id,
      sourcepage,
      tags,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LLM_API_URL}/update-file-chunk-tag`,
        requestBody,
        { headers: { Authorization: `Bearer ${token}`  } }
      );
      if (response.data) {
        toast({
          title: 'Success',
          description: 'Tags added successfully.',
          status: 'success',
          duration: 5000,
          position: 'top',
          isClosable: true,
        });
        setSelectedTags(tags);
      }
    } catch (error) {
      console.error('Error saving tags', error);
      toast({
        title: 'Error',
        description: 'Failed to save tags.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    } finally {
      onSaveComplete();
      setIsLoading(false); // Reset loading state after API call completes
    }
  };

  const groupOptionsByParent = (options: any) => {
    return options.reduce((acc: any, option: any) => {
      const parent = option.parent_tag_type_name || option.label; // Use label if parent_tag_type_name is null
      const key =
        parent === option.label ? parent : `${parent}: ${option.label}`; // Create a unique key for each parent-label pair

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push({ value: option.value, label: option.value });

      return acc;
    }, {} as { [key: string]: { value: string; label: string }[] });
  };

  const groupedOptions = groupOptionsByParent(options);

  return (
    <>
      {isEditing ? (
        Object.keys(groupedOptions).map((parent, index) => (
          <FormControl p={4} key={index}>
            <FormLabel>{parent}</FormLabel>
            <Select
              isSearchable={false}
              isMulti
              isDisabled={!isEditing}
              selectedOptionStyle="check"
              name="tags"
              options={groupedOptions[parent]}
              value={selectedItems.filter((item) =>
                groupedOptions[parent]?.some(
                  (option: any) => option.value === item.value
                )
              )}
              onChange={(newValue, actionMeta) =>
                handleChange(newValue, actionMeta, parent)
              }
              placeholder={`Select ${parent} tag...`}
              closeMenuOnSelect={false}
              chakraStyles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                  bg: 'transparent',
                  px: 2,
                  cursor: 'inherit',
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: 'none',
                }),
                groupHeading: (provided) => ({
                  ...provided,
                  fontWeight: 'bold',
                  color: 'gray',
                }),
                option: (provided) => ({
                  ...provided,
                  paddingLeft: '30px',
                }),
              }}
            />
          </FormControl>
        ))
      ) : (
        <FormControl p={4}>
          <FormLabel>Tags</FormLabel>
          <Flex flexWrap="wrap" gap={2}>
            {selectedTags.map((option, idx) => (
              <Tag key={idx} variant="subtle" colorScheme="blue">
                <TagLeftIcon boxSize="12px" as={MdLabel} />
                <TagLabel>{option}</TagLabel>
              </Tag>
            ))}
          </Flex>
        </FormControl>
      )}
      <Flex></Flex>
      {isEditing && (
        <>
          {isLoading ? (
            <Spinner />
          ) : (
            <Button
              width={'100px'}
              mr={4}
              colorScheme="blue"
              mt={-1}
              onClick={() => handleSaveTag()}
            >
              Save tag
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default TagMultiSelect;
