import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  chat?: {
    pageId: number | undefined;
    messages: any[];
    isLast: boolean;
  };
}

const initialState: AuthState = {
  chat: {
    pageId: undefined,
    messages: [],
    isLast: false,
  },
};

export const chatHistorySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setChatHistory: (state, { payload }: PayloadAction<AuthState['chat']>) => {
      state.chat = payload;
    },
    resetChatHistory: (state) => {
      state.chat = initialState.chat;
    },
  },
});

export const { setChatHistory, resetChatHistory } = chatHistorySlice.actions;

export default chatHistorySlice.reducer;
