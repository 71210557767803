import { createSlice } from '@reduxjs/toolkit';

export const inputMessageSlice = createSlice({
  name: 'inputMessage',
  initialState: "",
  reducers: {
    setInputMessage: (state, action) => action.payload,
  },
});

export const { setInputMessage } = inputMessageSlice.actions;

export default inputMessageSlice.reducer;

