import { Icon } from '@chakra-ui/react';
import {
  MdManageAccounts,
  MdOutlineLabel,
  MdOutlineList,
} from 'react-icons/md';

// Admin Import
import FileList from 'views/admin/filelists/FilelIst';
import PromptList from 'views/admin/prompts/Prompts';
import Tags from 'views/admin/tags/Tags';

const routes = [
  {
    name: 'File list',
    layout: '/admin',
    path: '/files',
    icon: (
      <Icon as={MdOutlineList} width="20px" height="20px" color="inherit" />
    ),
    component: FileList,
    collapse: false,
    rightElement: true,
  },
  {
    name: 'Prompts',
    layout: '/admin',
    path: '/prompts',
    icon: (
      <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />
    ),
    component: PromptList,
    collapse: false,
    rightElement: true,
  },
  {
    name: 'Tags',
    layout: '/admin',
    path: '/tags',
    icon: (
      <Icon as={MdOutlineLabel} width="20px" height="20px" color="inherit" />
    ),
    component: Tags, // Assuming this should be a different component
    collapse: false,
    rightElement: true,
  },
];

export default routes;
