import auth from "../hooks/auth";

export const sleep = (time: number) =>
    new Promise((res) => setTimeout(res, time));
  
export const calculateWindowSize = (windowWidth: number) => {
  if (windowWidth >= 1200) {
    return 'lg';
  }
  if (windowWidth >= 992) {
    return 'md';
  }
  if (windowWidth >= 768) {
    return 'sm';
  }
  return 'xs';
};

export const authLogin = (userName: string, password: string) => {
  return new Promise(async (resolve, reject) => {
    await sleep(500);

    const data = new FormData();
    const { getToken } = auth();
    data.append('username', userName);
    data.append('password', password);
 
    try {
      const response = await getToken();
      if (response != null) {
        resolve({ response });
      } else {
        reject({ message: 'Invalid response from server' });
      }
    } catch (error) {
      reject({ message: 'Error occurred while authenticating' });
    }
  });
};

export const getAuthStatus = () => {
  return new Promise(async (res, rej) => {
    await sleep(500);
    try {
      let authentication = localStorage.getItem('authentication');
      if (authentication) {
        authentication = JSON.parse(authentication);
        return res(authentication);
      }
      return res(undefined);
    } catch (error) {
      return res(undefined);
    }
  });
};