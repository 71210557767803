import nucomply from '../../assets/img/nucomply_reverse_1500x4501500w.png';
import { Link } from 'react-router-dom';

export function Nav() {
    return (
        <nav className="navbar navbar-expand-xl sticky-top navbar-dark bg-black4">
            <div className="container">
                <a href="index.html" className="navbar-brand"><img className="logo" src={nucomply} alt="startup.logo" style={{ height: '3.5em' }}/></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/compliance/chat" className="nav-link ">Product</Link>
                        </li>

                        <li className="nav-item ">
                            <Link to="/compliance/solutions" className="nav-link">Solutions</Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/compliance/pricing" className="nav-link ">Pricing</Link>
                        </li>

                        <li className="nav-item">
                            <a href="https://www.nuarca.com/about_us.html" className="nav-link ">About NuArca</a>
                        </li>

                        <li className="nav-item">
                            <Link to="/compliance/chat" className="nav-link ">Access NuComply</Link>
                        </li>

                    </ul>

                    <button onClick={() => window.location.href = '/signup'} className="btn btn-nuarca">JOIN TODAY</button>
                </div>
            </div>
        </nav>
    );
}