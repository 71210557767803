import { SearchIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Image,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import { useAbortController } from 'hooks/AbortControllerContext';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { MdHistory } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { RootState } from 'store/store';
import { groupChatHistoryByDate, stripHtmlTags } from './utility';

function SidebarContent(props: any) {


  const { setAbortController } = useAbortController();
  
  const { routes } = props;
  const { fetchUserChatHistory } = useAuth();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const chat = useSelector((state: RootState) => state.history.chat);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [showHistoryAccordion, setShowHistoryAccordion] = useState(false);
  const history = useHistory();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const session = useSelector(
    (state: RootState) => state.session.newSessionLoad
  );
  const justifyCenter = useBreakpointValue({ base: 'center', md: 'center' });
  const handleSearchHistory = (e: any) => {
    e.stopPropagation();
    history.push('/compliance/search-chat');
  };

  const fetchChatHistory = async (clear = false) => {
    try {
      console.info('  ChatHistory =======');
      setLoading(true);
      const data = clear
        ? await fetchUserChatHistory(true)
        : await fetchUserChatHistory();
      setPage(1);

      // setData((prevData) =>
      //   page === 1 ? data?.items : [...prevData, ...data?.items]
      // );
      setHasMore(!data?.isLast);
      setShowHistoryAccordion(true);
    } catch (error) {
      console.error(
        'Error fetching user active subscriptions or chat history:',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setHasMore(!chat?.isLast);
    setShowHistoryAccordion(true);
    if (profile?.userId) {
      if (!chat?.pageId) {
        await fetchChatHistory();
      }
    } else {
      setShowHistoryAccordion(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [profile, page]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const fetchDataOnNewMessage = async () => {
      if (profile?.userId) {
        setPage(1); // Reset to first page
        await fetchChatHistory(true); // Fetch from the first page
      }
    };

    if (session) {
      timer = setTimeout(fetchDataOnNewMessage, 0);
    }

    return () => clearTimeout(timer); // Clear the timer on component unmount or when messages change
  }, [session]);

  useEffect(() => {
    const handleScroll = async (e: any) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollHeight - scrollTop === clientHeight && hasMore && !loading) {
        // console.info(`Scrolling`, chat?.isLast);
        setPage((prevPage) => prevPage + 1);
        if (!chat?.isLast) {
          // console.info(`chat?.isLast`);
          // console.info(chat?.isLast);
          fetchChatHistory();
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, loading, profile]);

  const groupedHistory = groupChatHistoryByDate(chat?.messages);

  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      borderRadius="30px"
      maxW="300px"
      px="20px"
      justifyContent="space-between"
    >
      <Box>
        <Brand />
        <Stack direction="column" mt="8px" mb="auto">
          <Box pe={{ lg: '16px', '2xl': '16px' }}>
            <Links routes={routes} />
          </Box>
        </Stack>
      </Box>

      {showHistoryAccordion && (
        <Flex
          ref={containerRef}
          w="100%"
          h="100%"
          overflowY="auto"
          flexDirection="column"
          alignItems="start"
          sx={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#7551FF',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        >
          <VStack key="history" spacing={0} align="start" mb={4} w="100%">
            <Accordion allowToggle w="100%" ml={-3.5}>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Icon
                      as={MdHistory}
                      width="20px"
                      height="20px"
                      color="inherit"
                    />
                    <Box flex="1" textAlign="left" ml={4} fontWeight="bold">
                      Chat History
                    </Box>
                    <Box
                      ml={4}
                      onClick={handleSearchHistory}
                      cursor="pointer"
                      as="span"
                    >
                      <SearchIcon />
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} w="100%">
                  <Accordion allowToggle w="100%">
                    {Object.entries(groupedHistory).map(([group, chats]) => (
                      <AccordionItem key={group}>
                        <h2>
                          <AccordionButton>
                            <Box flex="1" textAlign="left">
                              {group}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} w="100%">
                          {chats.map((chat: any) => {
                            let solutionType = 'federal_content_expert';
                            let tab = '1';
                            let imageSrc =
                              '/img/solutions/askQuestionFederal.png';

                            if (
                              chat.solution &&
                              chat.solution.includes('state')
                            ) {
                              solutionType = 'state_content_expert';
                              tab = '2';
                              imageSrc =
                                '/img/solutions/askQuestionFederalnState.png';
                            } else if (
                              chat.solution &&
                              chat.solution.includes('canada')
                            ) {
                              solutionType = 'canada_content_expert';
                              tab = '3';
                              imageSrc = '/img/solutions/askQuestionCanada.png';
                            }

                            return (
                              <Flex
                                key={`chat-${chat.id}`}
                                w="100%"
                                alignItems="center"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  me="2px"
                                  mt={'20px'}
                                >
                                  <Image
                                    src={imageSrc}
                                    alt="logo"
                                    w="16px"
                                    h="16px"
                                  />
                                </Box>

                                <Tooltip
                                  label={stripHtmlTags(chat.title)}
                                  fontSize="md"
                                >
                                  <NavLink
                                  onClick={() => {
                                    const newAbortController = new AbortController();
                                    setAbortController(newAbortController);
                                  }}
                                    style={{
                                      marginLeft: '3px',
                                      marginTop: '20px',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    key={chat.id}
                                    to={`/compliance/chat?tab=${tab}&sessionId=${chat.id}`}
                                  >
                                    <Text fontSize="md" fontWeight="500">
                                      {stripHtmlTags(chat.title).length > 17
                                        ? `${stripHtmlTags(
                                            chat.title
                                          ).substring(0, 17)}...`
                                        : stripHtmlTags(chat.title)}
                                    </Text>
                                  </NavLink>
                                </Tooltip>
                              </Flex>
                            );
                          })}
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </AccordionPanel>
              </AccordionItem>

              {loading && (
                <VStack
                  key={'loading-panel'}
                  spacing={0}
                  align="center"
                  justify={justifyCenter}
                  mb={4}
                  w={'100%'}
                >
                  <Spinner />
                </VStack>
              )}
            </Accordion>
          </VStack>
        </Flex>
      )}
    </Flex>
  );
}

export default SidebarContent;
