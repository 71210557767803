export function Footer() {
    return (
        <footer className="footer">
            <div className="container">

                <p className="float-lg-right">
                    <a href="#">Back to top</a>
                </p>

                <div className="row">

                    <div className="col-sm-12">
                        <h4>
                            <a href="https://x.com/NuarcaLabs" target="_blank" style={{ marginRight: '4px' }}><i className="fab fa-x-twitter loud2"></i></a>
                            <a href="https://www.linkedin.com/company/nuarca-labs/" target="_blank"><i className="fab fa-linkedin-in loud2"></i></a> <a href="https://discord.gg/x4uKk72j" target="_blank"><i className="fab fa-discord loud2"></i></a>
                        </h4>

                        <p>&copy;<span id="year"></span> NuArca</p>
                    </div>

                    <div className="col-sm-4">
                        <p> 304 Cambridge Rd.
                            <br/> Suite 210 <br/>Woburn, MA 01801

                            <br/>
                            <a href="tel:617.415.7714">617.415.7714</a>
                            <br/>
                            <a href="mailto: info@nuarca.com">email: info@nuarca.com</a>
                        </p>
                    </div>

                    <div className="col-sm-3">
                        <ul className="list-unstyled">
                            <li><a href="index.html">Home</a></li>

                            <li><a href="contact.html">Contact</a></li>

                        </ul>
                    </div>

                </div>
            </div>

        </footer>
    );
}