import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';
import Card from 'components/card/Card';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { stripHtmlTags } from 'components/sidebar/components/utility';
import useAuth from 'hooks/auth';
import * as React from 'react';
import { useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/store';
import './table.css';

type RowObj = {
  date: string | Date;
  productName: string;
  chat: string;
  id: string;
  productImage: string;
};

export default function SearchTableOrders() {
  const textColor = useColorModeValue('navy.700', 'white');
  const textSecondaryColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const history = useHistory();

  const [columnFilters, setColumnFilters] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [data, setData] = React.useState<RowObj[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(0); // Changed to 0 to be consistent with React Table pagination
  const [isLoading, setIsLoading] = React.useState(false);
  const { ensureValidToken } = useAuth();
  const profile = useSelector((state: RootState) => state.auth.authentication);

  const highlightText = (text: any, highlight: any) => {
    if (!highlight.trim()) {
      return text;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part: any, index: any) =>
      regex.test(part) ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const columnHelper = createColumnHelper<RowObj>();
  const columns = [
    columnHelper.accessor('date', {
      id: 'date',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="xs"
          fontWeight="500"
          color={textSecondaryColor}
        >
          DATE
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          whiteSpace="nowrap"
        >
          {info.getValue().toString()}
        </Text>
      ),
    }),
    columnHelper.accessor('productName', {
      id: 'productName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="xs"
          fontWeight="500"
          color={textSecondaryColor}
        >
          SOLUTIONS
        </Text>
      ),
      cell: (info) => {
        const { productImage, productName } = info.row.original;
        return (
          <Box display="flex" alignItems="center" whiteSpace="nowrap">
            <Image
              src={productImage}
              alt="solution logo"
              w="16px"
              h="16px"
              mr="2"
            />
            <Text color={textColor} fontSize="md" fontWeight="500">
              {productName}
            </Text>
          </Box>
        );
      },
    }),
    columnHelper.accessor('chat', {
      id: 'chat',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="xs"
          fontWeight="500"
          color={textSecondaryColor}
        >
          CHAT
        </Text>
      ),
      cell: (info) => (
        <Tooltip label={stripHtmlTags(info.getValue())} fontSize="md">
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            isTruncated
            maxW="1200px"
          >
            {stripHtmlTags(highlightText(info.getValue(), globalFilter))}
          </Text>
        </Tooltip>
      ),
    }),
  ];

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 30,
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: -1, // Indicate the total number of pages is unknown
  });
  const fetchChatHistory = async (page: number) => {
    const token = await ensureValidToken(localStorage.getItem('token'));

    setIsLoading(true);
    try {
      const getSessionURL = `${process.env.REACT_APP_LLM_API_URL}/get-all-chat-history`;
      const requestBody = {
        partner_name: 'federal_banking_compliance',
        user_id: profile?.userId,
        page_id: page + 1, // API expects 1-based page index
      };
      const response = await axios.post(getSessionURL, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sessions = response.data;

      const newItems = sessions.map((session: any) => {
        let productName;
        let productImage;

        if (session.solution && session.solution.includes('state')) {
          productName = 'Federal & State';
          productImage = '/img/solutions/askQuestionFederalnState.png';
        } else if (session.solution && session.solution.includes('canada')) {
          productName = 'Canada';
          productImage = '/img/solutions/askQuestionCanada.png';
        } else {
          productName = 'US Federal';
          productImage = '/img/solutions/askQuestionFederal.png';
        }

        return {
          id: session.session_id,
          productName: productName,
          productImage: productImage,
          chat: session.content[0].content,
          date: session.create_date.split('T')[0],
          solution: session.solution || '',
        };
      });

      setData(newItems);
      setHasMore(!sessions[0]?.is_last_page);
    } catch (error) {
      console.error(
        'Error fetching user active subscriptions or chat history:',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChatHistory(pagination.pageIndex);
  }, [pagination.pageIndex]);

  const handleRowClick = (row: any) => {
    const tab = row.original.solution.includes('canada')
      ? 3
      : row.original.solution.includes('state')
      ? 2
      : 1;
    history.push(`/compliance/chat?tab=${tab}&sessionId=${row.original.id}`);
  };

  const handleNextPage = () => {
    if (hasMore) {
      table.setPageIndex(table.getState().pagination.pageIndex + 1);
    }
  };

  const handlePreviousPage = () => {
    if (table.getState().pagination.pageIndex > 0) {
      table.setPageIndex(table.getState().pagination.pageIndex - 1);
    }
  };

  return (
    <Card flexDirection="column" w="100%" px="0px">
      <Flex align="center" justify="flex-start" w="100%" px="20px">
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={(value: any) => setGlobalFilter(String(value))}
          placeholder="Search conversation"
        />
        <Button
          variant="primary"
          py="20px"
          px="16px"
          fontSize="sm"
          borderRadius="45px"
          w={{ base: '100%', md: '140px' }}
          h="44px"
        >
          Search
        </Button>
      </Flex>
      <Flex maxW="100%" overflowX={{ base: 'scroll', lg: 'hidden' }}>
        {isLoading ? (
          <Flex justify="center" align="center" w="100%" py="20px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Table maxW="100%" variant="simple" color="gray.500" mb="24px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      pe="10px"
                      py="22px"
                      borderColor={borderColor}
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      <Flex
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        justify="space-between"
                        align="center"
                        fontSize="xs"
                        fontWeight="500"
                        color={textSecondaryColor}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  _hover={{
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    cursor: 'pointer',
                    backgroundColor: 'rgba(0,0,0,0.05)',
                  }}
                  onClick={() => handleRowClick(row)}
                  transition="all .3s ease-in-out"
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      borderColor={borderColor}
                      py="20px"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Flex>
      <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
        <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
          <Button
            variant="ghost"
            color={brandColor}
            onClick={handlePreviousPage}
            isDisabled={pagination.pageIndex === 0}
            leftIcon={<Icon as={MdChevronLeft} w="24px" h="24px" />}
          >
            Previous
          </Button>

          <Button
            variant="no-effects"
            transition="all .5s ease"
            w="40px"
            h="40px"
            borderRadius="50%"
            // bg={'transparent'}
            bg={brandColor}
            border={'none'}
            _hover={{
              bg: 'whiteAlpha.100',
            }}
          >
            <Text fontSize="sm" color={textColor}>
              {pagination.pageIndex + 1}
            </Text>
          </Button>
          <Button
            variant="ghost"
            color={brandColor}
            onClick={handleNextPage}
            isDisabled={!hasMore}
            rightIcon={<Icon as={MdChevronRight} w="24px" h="24px" />}
          >
            Next
          </Button>
        </Stack>
      </Flex>
    </Card>
  );
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: any) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <SearchBar
      value={value}
      me="10px"
      onChange={(e: any) => setValue(e.target.value)}
      h="44px"
      w={{ lg: '390px' }}
      borderRadius="40px !important"
      placeholder="Search message"
      {...props}
    />
  );
}
