export const ToastComponent = {
  components: {
    Toast: {
      baseStyle: (props: any) => ({
        container: {
          opacity: '1', // or any other color you prefer
        },
      }),
    },
  },
};
