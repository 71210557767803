/*eslint-disable*/
'use client';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import Card from 'components/card/Card';
import MessageBox from 'components/MessageBox';
import useAuth from 'hooks/auth';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/store';
import { OpenAIModel } from 'types/types';
import ComingSoonMessage from '../CommingSoon';

export default function ProductReview() {
  // *** If you use .env.local variable for your API key, method which we recommend, use the apiKey variable commented below
  // Input States
  const [topic, setTopic] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [language, setLanguage] = useState<
    | ''
    | 'English'
    | 'Chinese'
    | 'Spanish'
    | 'Arabic'
    | 'Hindi'
    | 'Italian'
    | 'Portuguese'
    | 'Russian'
    | 'Japanese'
    | 'Romanian'
    | 'German'
  >('');
  const [words, setWords] = useState<200 | 300 | 400 | 500 | 600>(200);
  // Response message
  const [outputCode, setOutputCode] = useState<string>('');
  // ChatGPT model
  const [model, setModel] = useState<OpenAIModel>('gpt-3.5-turbo');
  // Loading state
  const [loading, setLoading] = useState<boolean>(false);
  // API Key
  // const [apiKey, setApiKey] = useState<string>();
  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' }
  );
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const toast = useToast();
  const profile = useSelector((state: RootState) => state.auth.authentication);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const { login  } = useAuth();
  const { ensureValidToken } = useAuth();

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setUploadedFile(file);
  };

  const handleUpload = async () => {
    const token = await ensureValidToken(localStorage.getItem('token')); 
    
    if (uploadedFile) {
      try {
        setUploading(true);

        const formData = new FormData();
        formData.append('pdf_file', uploadedFile);

        const response = await axios.post(
          `${process.env.REACT_APP_LLM_API_URL}/upload-file-to-azure?&user_id=${profile?.userId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}` ,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.data === true) {
          setUploadedFile(null);
          toast({
            title: 'Upload Successful',
            description: 'The file has been successfully uploaded.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
        } else {
          toast({
            title: 'Upload Failed',
            description: 'Something went wrong.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
            variant: 'subtle',
          });
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast({
          title: 'Upload Failed',
          description: 'Failed to upload the file. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          variant: 'subtle',
        });
      } finally {
        setUploading(false);
      }
    } else {
      console.info('No file selected or no partner/persona selected.');
    }
  };

  // -------------- Main API Handler --------------
  const handleTranslate = async () => {
    const maxCodeLength = model === 'gpt-3.5-turbo' ? 60 : 60;
    const apiKey = localStorage.getItem('apiKey');

    // Chat post conditions(maximum number of characters, valid message etc.)
    // if (!apiKey?.includes('sk-')) {
    //   alert('Please enter an API key.');
    //   return;
    // }

    if (!profile?.idToken || !profile?.userId) {
      login();
      return;
    }

    if (!topic) {
      alert('Please enter your topic.');
      return;
    }

    if (title.length > maxCodeLength) {
      alert(
        `Please enter code less than ${maxCodeLength} characters. You are currently at ${title.length} characters.`
      );
      return;
    }

    if (!language) {
      alert('Please choose your Policy Type.');
      return;
    }

    toast({
      title: 'Hey there! 👋',
      description:
        "We're hard at work developing this feature to enhance your experience. Stay tuned for exciting updates! 🚀",
      status: 'info',
      duration: 3000,
      isClosable: true,
      position: 'top',
      variant: 'subtle',
    });
  };

  // -------------- Copy Response --------------
  const handleFileDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setUploadedFile(file);
  };

  // -------------- Input Value Handler --------------
  const handleTopic = (Event: any) => {
    setTopic(Event.target.value);
  };
  const handleTitle = (Event: any) => {
    setTitle(Event.target.value);
  };
  const handleLanguage = (Event: any) => {
    setLanguage(Event.target.value);
  };
  const handleWords = (Event: any) => {
    setWords(Event.target.value);
  };

  // Initializing apiKey with localStorage value
  // useEffect(() => {
  //   setApiKey(apiKeyApp);
  // }, [apiKey]);

  return (
    <div style={{ marginTop: '100px' }}>
      <Flex
        w="100%"
        direction="column"
        position="relative"
        mt={{ base: '70px', md: '0px', xl: '0px' }}
      >
        <ComingSoonMessage />
        <Flex
          mx="auto"
          w={{ base: '100%', md: '100%', xl: '100%' }}
          maxW="100%"
          justify="center"
          direction={{ base: 'column', md: 'row' }}
        >
          <FormControl mb={4}>
            <FormLabel htmlFor="file-drop" fontWeight="bold">
              Drop or Select a File
            </FormLabel>
            <Box
              id="file-drop"
              border="2px dashed"
              borderRadius="10px"
              borderColor="gray.300"
              h="200px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onDrop={handleFileDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              {uploadedFile ? (
                <Box p={4}>
                  <Text fontWeight="bold">Selected File:</Text>
                  <Text>{uploadedFile.name}</Text>
                  <Flex justifyContent="center">
                    {uploading ? (
                      <Spinner />
                    ) : (
                      <Button mt={3} colorScheme="blue" onClick={handleUpload}>
                        Upload File to Index
                      </Button>
                    )}
                  </Flex>
                </Box>
              ) : (
                <Text>Select or drop a file here</Text>
              )}
            </Box>
            <input
              type="file"
              accept=".txt,.pdf,.doc,.docx"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
          </FormControl>

          <Card
            minW={{ base: '100%', md: '40%', xl: '476px' }}
            maxW={{ base: '100%', md: '40%', xl: '476px' }}
            h="min-content"
            me={{ base: '0px', md: '20px' }}
            mb={{ base: '20px', md: '0px' }}
          >
            <Text
              fontSize={'30px'}
              color={textColor}
              fontWeight="800"
              mb="10px"
            >
              Policy Topic
            </Text>
            <Text fontSize={'16px'} color="gray.500" fontWeight="500" mb="30px">
              Provide as much information as posssible about your existing
              policies in this area.
            </Text>
            <Textarea
              border="1px solid"
              borderRadius={'10px'}
              borderColor={borderColor}
              p="15px 20px"
              mb="28px"
              minH="324px"
              fontWeight="500"
              _focus={{ borderColor: 'none' }}
              color={textColor}
              placeholder="Type here the topic..."
              _placeholder={placeholderColor}
              onChange={handleTopic}
            />

            <FormLabel
              display="flex"
              ms="10px"
              htmlFor={'lang'}
              fontSize="md"
              color={textColor}
              letterSpacing="0px"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Select your Policy type
            </FormLabel>
            <Select
              border="1px solid"
              borderRadius={'10px'}
              borderColor={borderColor}
              h="60px"
              id="lang"
              _focus={{ borderColor: 'none' }}
              mb="28px"
              onChange={handleLanguage}
            >
              <option value="Return policy" style={{ background: 'white' }}>
                Return policy
              </option>
              <option value="Refund policy" style={{ background: 'white' }}>
                Refund policy
              </option>
              <option value="Privacy policy" style={{ background: 'white' }}>
                Privacy policy
              </option>
            </Select>

            <Button
              py="20px"
              px="16px"
              fontSize="md"
              variant="primary"
              borderRadius="45px"
              w={{ base: '100%' }}
              mt="28px"
              h="54px"
              onClick={handleTranslate}
              isLoading={loading ? true : false}
              _hover={{
                boxShadow:
                  '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%) !important',
                _disabled: {
                  bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)',
                },
              }}
            >
              Generate your PolicyGeneator
            </Button>
          </Card>
          <Card maxW="100%" h="100%">
            <Text
              fontSize={'30px'}
              color={textColor}
              fontWeight="800"
              mb="10px"
            >
              Policy Output
            </Text>
            <Text fontSize={'16px'} color="gray.500" fontWeight="500" mb="30px">
              All policies are considered drafts and must be reviewed by
              appropriate internal committees before being implemented
            </Text>
            <MessageBox output={outputCode} />
            <Button
              variant="transparent"
              border="1px solid"
              borderColor={borderColor}
              borderRadius="full"
              maxW="160px"
              ms="auto"
              fontSize="md"
              w={{ base: '300px', md: '420px' }}
              h="54px"
              onClick={() => {
                if (outputCode) navigator.clipboard.writeText(outputCode);
                toast({
                  title: outputCode
                    ? `Content succesfully copied!`
                    : `Generate some content first!`,
                  position: 'top',
                  status: outputCode ? 'success' : `error`,
                  isClosable: true,
                });
              }}
            >
              Copy text
            </Button>
          </Card>
        </Flex>
      </Flex>
    </div>
  );
}
