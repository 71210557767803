import { Link } from 'react-router-dom';
import headerImage from '../../assets/img/header-1.jpg';

export function Banner() {
    return (
        <section id="" className="nucomply_assistant py-5 body-landing">
            <div className="container ">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <h3 className="h1 text-light p2"><strong> Introducing NuComply! </strong></h3>
                        <p className='p2'> Nucomply is the trustworthy expert compliance assistant that empowers financial services professionals to confidently and efficiently understand, assess, communicate internally and externally, operationalize governance, and ensure compliance for their ever-expanding regulatory obligations.
                        </p>
                        <p className="py-2 p2">For more information, visit our website <Link to="/compliance" className="btn btn-nuarca btn-sm ">HERE</Link> or contact our customer success team at 877.4nuarca.</p>
                        <p className="p2"><button onClick={() => window.location.href = '/signup'}  className="btn btn-nuarca btn-md ">SIGN UP TODAY</button></p>

                    </div>
                    <div className="col-lg-6 align-self-center text-center d-none d-lg-block">
                        <img src={headerImage} alt="" className="rounded-5 img-fluid "/>
                    </div>
                </div>
            </div>
        </section>
    )
}