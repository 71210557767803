'use client';

// Chakra imports
import { Box } from '@chakra-ui/react';
import CheckTable from 'components/tables/CheckTable';
import { useEffect } from 'react';

export default function SearchChat() {
  // Refresh page on load
  useEffect(() => {}, []);

  // Chakra Color Mode
  return (
    <Box
      mt={{ base: '0px', md: '50px', xl: '100px' }}
      mb={{ base: '0px', md: '80px', xl: '160px' }}
    >
      <CheckTable />
    </Box>
  );
}
