import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  authentication?: any;
  token?: string;
}

const initialState: AuthState = {
  authentication: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthentication: (state: AuthState, { payload }: { payload: any }) => {
      state.authentication = payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setAuthentication, setToken } = authSlice.actions;

export const selectToken = (state: AuthState) => state.authentication?.token;

export default authSlice.reducer;

