import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0 hook
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import useAuth from 'hooks/auth';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook
import { useHistory, useLocation } from 'react-router-dom';
import { setAuthentication, setToken } from 'store/reducers/auth';

const RedirectPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0(); // Use useAuth0 hook to get authentication status
  const { login, ensureValidToken, fetchUserProduct, fetchSubscriptionPlan } =
    useAuth();
  const toast = useToast();
  const params = new URLSearchParams(location.search);
  const path = params.get('path');
  const supportForgotPassword = params.get('supportForgotPassword') === 'true';
  const supportSignUp = params.get('supportSignUp') === 'true';
  const success = params.has('success')
    ? params.get('success') === 'true'
    : true;
  const message = params.get('message');
  const [retry, setRetry] = useState(false);
  const alreadyVerified = useRef(false);

  const goBack = () => {
    if (path && path.includes('admin')) {
      history.replace('/admin/files');
    } else {
      history.replace('/compliance/chat');
    }

    toast({
      title: 'Welcome!',
      description: 'You have been successfully logged in.',
      status: 'info',
      duration: 1000,
      isClosable: true,
      position: 'top',
      // variant: 'subtle',
    });
  };

  const verifyUserByEmail = async () => {
    if (isAuthenticated && user.email_verified && !alreadyVerified.current) {
      alreadyVerified.current = true
      try {
        const token = await ensureValidToken(localStorage.getItem('token'));
        const verifyUserUrl = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/auth/verify-user`;
        const requestBody = { id: user.sub };

        const response = await axios.post(verifyUserUrl, requestBody, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.success) {
          const apiData = response.data.data;

          const authData = {
            idToken: true,
            userId: apiData.user_id,
            name: apiData?.name,
            organization: apiData?.organization,
            title: apiData?.title,
            userEmail: apiData?.email,
            userPlan: apiData.user_plan,
            verified: user.email_verified,
            isAdmin: apiData.is_admin,
            user: user,
          };

          // Save authentication data in session storage
          localStorage.setItem('authentication', JSON.stringify(authData));

          // Dispatch authentication data to Redux store
          dispatch(setAuthentication(authData as any));
          await fetchUserProduct();
          await fetchSubscriptionPlan();

          // Show success toast

          // Redirect after a short delay
          const timer = setTimeout(goBack, 2000);
          return () => clearTimeout(timer);
        } else {
          // Handle login failure from API response
          console.error('Login failed:', response.data.message);

          toast({
            title: 'Login failed',
            description:
              'There was an issue from server logging you in. Please try again later.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top',
            // variant: 'subtle',
          });

          // Redirect to home page
          goBack();
        }
      } catch (error) {
        console.error('Error during user verification:', error);

        toast({
          title: 'Error',
          description:
            'There was an issue from server logging you in. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
          // variant: 'subtle',
        });

        // Redirect to home page
        goBack();
      }
    } else if (!isAuthenticated && !isLoading) {
      // If not authenticated and loading is complete, initiate login
      setTimeout(() => {
        login();
      }, 2000);
    }
  };

  useEffect(() => {
    if (supportForgotPassword && success) {
      history.replace('/redirect');
    } else if (supportSignUp && success) {
      history.replace('/redirect');
    } else if (success) {
      verifyUserByEmail();
    } else {
      setRetry(true);
    }
  }, [
    dispatch,
    isAuthenticated,
    user,
    isLoading,
    login,
    goBack,
    toast,
    success,
    supportForgotPassword,
    supportSignUp,
  ]);

  return (
    <>
      <Box
        bg="navy.900"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* Alert component */}
        {isLoading ? ( // Check if authentication is in progress
          <Alert
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            width="400px"
            borderRadius="md"
            boxShadow="lg"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Loading...
            </AlertTitle>
            <Spinner />
            <AlertDescription maxWidth="sm">
              Please wait while we check your authentication status.
            </AlertDescription>
          </Alert>
        ) : retry ? (
          <Alert
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            width="400px"
            borderRadius="md"
            boxShadow="lg"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Login Failed!
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {message ||
                'There was an issue logging you in. Please try again.'}
            </AlertDescription>
            <Button
              variant="primary"
              mt="4"
              mb="3"
              w={{ base: '160px', md: '140px' }}
              _hover={{
                boxShadow:
                  '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%) !important',
                _disabled: {
                  bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%)',
                },
              }}
              onClick={() => loginWithRedirect()}
            >
              Retry Login
            </Button>
          </Alert>
        ) : isAuthenticated ? (
          user.email_verified ? (
            <Alert
              status="info"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="400px"
              borderRadius="md"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Loading...
              </AlertTitle>
              <Spinner />
              <AlertDescription maxWidth="sm">
                We're redirecting you.
              </AlertDescription>
              {!success && message && (
                <AlertDescription maxWidth="sm">{message}</AlertDescription>
              )}
            </Alert>
          ) : (
            <Alert
              status="info"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              width="400px"
              borderRadius="md"
              boxShadow="lg"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Your Email Is Not Verified Yet!
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                We have sent a verification link to
              </AlertDescription>
              <AlertDescription maxWidth="sm">{user.email}</AlertDescription>
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Please log in after verifying your email.
              </AlertTitle>
              <Button
                variant="primary"
                mt="4"
                mb="3"
                w={{ base: '160px', md: '140px' }}
                _hover={{
                  boxShadow:
                    '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                  bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%) !important',
                  _disabled: {
                    bg: 'linear-gradient(15.46deg, rgb(54, 82, 186) 26.3%, rgb(6, 128, 255) 86.4%)',
                  },
                }}
                onClick={() => loginWithRedirect()}
              >
                Login
              </Button>
            </Alert>
          )
        ) : (
          <Alert
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            width="400px"
            borderRadius="md"
            boxShadow="lg"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Not Logged In!
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              We're redirecting you to Login.
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </>
  );
};

export default RedirectPage;
